import request from '@/utils/request'
// 交易类别
export function category(params) {
  return request({
    url: 'web/home/subcategory.json',
    method: 'get',
    params
  })
}
// 交易列表
export function list(params) {
  return request({
    url: 'web/home/transactionInformationList.json',
    method: 'get',
    params
  })
}
// 交易详情
export function del(params) {
  return request({
    url: 'web/home/projectDetail.json',
    method: 'get',
    params
  })
}
// 获取详情附件
export function file(params) {
  return request({
    url: 'standalonfile/operate/getlist.json',
    method: 'get',
    params
  })
}