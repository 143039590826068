<template>
  <div class="con btw-l mywidth">
    <div class="conl">
      <conl-news :pagination='true' :list='listdata' :total='total' :loading='loading' @type='gettype' @sou='getsou' @page='getpage'></conl-news>
      <img class="adv" src="@assets/img/adv.jpg" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
import { list} from "@/api/trans.js";
import ConlNews from "./com/list-news";
export default {
  name: "Trans",
  components: {
    ConlNews,
  },
  data () {
    return {
      pgData: {
                pageindex: 1,
                pagesize: 10,
                type:'all',
                citycode:'',
                parent:'',
                status:'',
                agent:'',
                operation:'ggname',
                content:'',
                starttime: '',
                endtime: '',
            },
        listdata:null,
        total:0,
        loading:true
    }
  },
  mounted () {
    this.getdel()
  },
  methods: {
     getdel() {
       this.loading = true
      list(this.pgData).then((data) => {
        this.listdata = data.rows;
        this.total = data.total
        this.loading = false
      });
    },
        // 选择类型
    gettype(val){
      this.pgData.type = val
      this.getdel()
    },
    // 搜索
    getsou(val){
      this.pgData.pageindex = 1
      this.pgData.searchcontent = val
      this.getdel()
    },
    getpage(val){
       this.pgData.pageindex = val
      this.getdel()
    }

    
  }
};
</script>

<style lang="less" scoped>
.con {
  z-index: 10;
  margin-top: 20px;
  position: relative;
  .conl {
  width:100%;
  }
  .adv {
    margin: 15px auto;
    width: 100%;
  }
}

</style>